import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    headers.set('Accept', 'application/json')

    // if (token) {
    //   headers.set('authorization', `Bearer ${token}`)
    // }
    return headers
  },
})

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['posts', 'postById', 'favourite', 'entities', 'patterns', 'teams', 'logs'],
  endpoints: () => ({}),
})
